<template>
  <div>
    <div
      class="text-center d-flex justify-center align-center"
      style="height: 80vh;"
      v-if="isLoading"
    >
      <div>
        <v-progress-circular
          class="text-center"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <template v-if="!isLoading">
      <v-container
        fluid
        class="mb-10"
      >
        <v-row>
          <v-col cols="3">
            <BasePhone
              :phone-info="detail.advertPhones"
              :item-id="detail.id"
              :attribute-to-save="'advertPhones'"
              :category-name="componentCategory"
              :is-advert-phone="true"
              @input="handleInput($event)"
              @detail="$emit('detail', $event)"
            >
              <template #btn>
                <RelatedAdverts
                  :phones="detail.advertPhones"
                  :item-id="detail.id"
                  :local-key="localKey"
                  @detail="$emit('detail', $event)"
                >
                </RelatedAdverts>
                <RelatedClients
                  :phones="detail.advertPhones"
                  :client-category="'1793'"
                  @detail="$emit('detail', $event)"
                ></RelatedClients>
                <RelatedPhones
                  :phones="detail.advertPhones"
                  @detail="$emit('detail', $event)"
                />
              </template>
            </BasePhone>
          </v-col>
          <v-col cols="9">
            <Header
              :id="detail.id"
              :local-key="localKey"
              :created-at="detail.created_at"
              :updated-at="detail.updated_at"
              :source="detail.source"
              :user-in-charge="detail.userInCharge"
              :advert-extra.sync="detail.advertExtras"
              :delay-until="detail.delay_until"
              :exclusive-status.sync="detail.exclusive_status"
              :publishedBy="detail.publishedBy"
              :auto-updated-at.sync="autoUpdatedAt"
              :address="detail.building.mainBuildingAddress"
              :floor="detail.floor"
              :room-count="detail.room_count"
              :area-common="detail.area_common"
              :advert-external-ids="detail.advertExternalIds"
              :common-desc="detail.description_default"
              @detail="$emit('detail', $event)"
            />
            <BuildingInfo
              v-model="detail.building"
              :advert-floors.sync="detail.total_floors"
              :advert-building-material="detail.building_material"
            />
            <AdvertInfo
              :address.sync="detail.building.mainBuildingAddress"
              :flat-type.sync="flatType"
              :flat-number.sync="detail.flat_number"
              :room-count.sync="detail.room_count"
              :floor.sync="detail.floor"
              :flat-status.sync="detail.flat_status"
              :price-history.sync="detail.price_history"
              :area-common.sync="detail.area_common"
              :area-living.sync="detail.area_living"
              :kitchen-type.sync="kitchenType"
              :area-kitchen.sync="detail.area_kitchen"
              :raw-data="detail.raw_data"
              :advert-external-ids.sync="detail.advertExternalIds"
            />
            <DelayUntil
              :key="localKey"
              :delay-until.sync="detail.delay_until"
              :delay-comment.sync="detail.delay_comment"
              @update:delay-until="handleDelayUntilChange"
            />
            <AdvertDescriptions
              :common-desc.sync="detail.description_default"
              :public-desc.sync="detail.description_public"
              :service-desc.sync="detail.description_service"
              :images.sync="detail.advertImages"
            />
          </v-col>
        </v-row>
      </v-container>
      <BaseButtonBar>
        <template #default>
          <BaseBtn
            color="primary"
            icon="mdi-content-save"
            class="mr-2"
            small
            :loading="isSavingData"
            title="Сохранить"
            @click="saveAndUpdate(false)"
          />
          <BaseBtn
            color="light-green white--text mr-2"
            dark
            icon="mdi-content-save"
            class="mr-2"
            small
            :loading="isSavingData"
            title="Сохранить как..."
            @click="saveAs = !saveAs"
          />
          <SaveAsDialog
            v-model="saveAs"
            :item-status.sync="detail.adv_status"
            :items="advertStatusItems"
            :text="'Статус объявления'"
            @saveAs="saveAndUpdate"
          />
          <BaseBtn
            color="error"
            icon="mdi-delete"
            class="mr-2"
            small
            :disabled="!$can('agent', 'removeAdvert')"
            :loading="isSavingData"
            title="Удалить"
            @click="confirmDelete = !confirmDelete"
          />
          <DeleteDialog
            @delete="deleteDetail"
            v-model="confirmDelete"
            :text="'Действительно хотите удалить объявление?'"
          />
        </template>
        <template #custom>
          <PublishedCheckbox
            :key="localKey"
            class="checkbox"
            :is-published.sync="detail.is_published"
            :published-at.sync="detail.published_at"
            :is-disabled="isDisabled"
          />
          <v-checkbox
            class="checkbox"
            v-model="detail.is_editing"
            :false-value="0"
            :true-value="1"
            label="Поднимать в выдаче"
          ></v-checkbox>
          <AttributeHistory
            :attribute.sync="detail.price_history"
          />
          <Price
            :price.sync="detail.price"
            :price-history.sync="detail.price_history"
          />
        </template>
      </BaseButtonBar>
    </template>
  </div>
</template>

<script>
import Header from '@/components/views/admin/advert/Detail/Header'
import detail from '@/components/mixins/detail'
import formatters from '@/components/mixins/formatters'
import AdvertInfo from '@/components/views/admin/advert/Detail/AdvertInfo'
import BuildingInfo from '@/components/views/admin/advert/Detail/BuildingInfo'
import AdvertDescriptions from '@/components/views/admin/advert/Detail/AdvertDescriptions'
import BaseButtonBar from '@/components/views/admin/common/BaseButtonBar'
import BaseBtn from '@/components/views/admin/common/BaseButtonBar/BaseBtn'
import DeleteDialog from '@/components/views/admin/common/BaseButtonBar/DeleteDialog'
import PublishedCheckbox from '@/components/views/admin/advert/Detail/ButtonBar/PublishedCheckbox'
import AttributeHistory from '@/components/views/admin/advert/Detail/ButtonBar/AttributeHistory'
import Price from '@/components/views/admin/advert/Detail/ButtonBar/Price'
import SaveAsDialog from '@/components/views/admin/common/BaseButtonBar/SaveAsDialog'
import DelayUntil from '@/components/views/admin/advert/Detail/DelayUntil'
import * as Config from '@/config'
import { minValue, required } from 'vuelidate/lib/validators'
import BasePhone from '@/components/views/admin/common/BasePhone'
import EmptyPhone from '@/components/views/admin/common/BasePhone/EmptyPhone'
import RelatedAdverts from '@/components/views/admin/common/BasePhone/RelatedAdverts'
import RelatedPhones from '@/components/views/admin/common/RelatedPhones'
import RelatedClients from '@/components/views/admin/common/BasePhone/RelatedClients'
import Building from '@/components/structures/building'

export default {
  mixins: [detail, formatters],
  name: 'Detail',
  components: {
    RelatedClients,
    BasePhone,
    EmptyPhone,
    SaveAsDialog,
    Price,
    AttributeHistory,
    PublishedCheckbox,
    DelayUntil,
    DeleteDialog,
    BaseButtonBar,
    BaseBtn,
    AdvertDescriptions,
    BuildingInfo,
    AdvertInfo,
    Header,
    RelatedAdverts,
    RelatedPhones
  },
  data () {
    return {
      componentCategory: 'advert',
      autoUpdatedAt: true,
      confirmDelete: false,
      saveAs: false,
      localKey: null,
      isDisabled: false
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      floor: {
        required,
        minValue: minValue(1)
      },
      area_common: {
        required,
        minValue: minValue(0.1)
      },
      room_count: {
        required,
        minValue: minValue(1)
      },
      flat_type: {
        required
      },
      flat_status: {
        required
      },
      kitchen_type: {
        required
      },
      description_public: {
        required
      }
    }
  },
  computed: {
    flatType: {
      get () {
        return this.detail.flat_type
      },
      set (value) {
        this.detail.flat_type = value
      }
    },
    kitchenType: {
      get () {
        return this.detail.kitchen_type
      },
      set (value) {
        this.detail.kitchen_type = value
      }
    },
    advertStatusItems () {
      let result = []
      const statuses = this.$store.getters['serviceData/optionsList']('advert.adv_status', false)
      if (statuses) {
        result = statuses.filter(item => item.id !== 5)
      }
      return result
    }
  },
  watch: {
    detail: {
      handler () {
        if (this.detail && !this.detail.building) {
          this.detail.building = new Building()
        }
      },
      deep: true
    },
    'detail.is_published': {
      handler () {
        if (this.detail && this.detail.is_published) {
          this.$v.$touch()
        } else {
          this.$v.$reset()
        }
      },
      immediate: true
    },
    'detail.delay_until': {
      handler () {
        this.handleDelayUntilChange()
      }
    }
  },
  methods: {
    async hasErrors () {
      let canSave = false
      if (!this.detail.advertPhones.length || !this.detail.advertPhones[0].phone.id) {
        canSave = 'Введите номер телефона'
      }
      if (!this.detail.building || !this.detail.building.mainBuildingAddress.id) {
        canSave = 'Укажите адрес'
      }

      if (this.$v.$anyError) {
        canSave = 'Заполните необходимые поля для публикации'
      }
      if (this.detail.is_published) {
        if (!this.detail.advertPhones.some(advertPhone => advertPhone.phone.status === Config.phoneStatuses.existed)) {
          canSave = 'Хотя бы один номер телефонов должен иметь статус Настоящий для публикации'
        }
        if (!this.detail.building.is_verified) {
          canSave = 'Адрес дома должен быть подтверждён'
        }
        /*if (!this.detail.is_verified) {
          canSave = 'Объявление должно быть подтверждено'
        }*/
      }
      return canSave
    },
    saveAndUpdate (saveAs) { /* localKey прокидывается до поиска клиентов для этого объявления, и там обновляет список и статусы*/
      if (this.saveDetail(saveAs)) {
        this.localKey++
      }
    },
    handleDelayUntilChange () {
      if (this.detail.delay_until) {
        this.$set(this.detail, 'is_published', 0)
        this.isDisabled = true
      } else {
        this.detail.delay_comment = null
        this.isDisabled = false
      }
      this.localKey++
    }
  }
}
</script>

<style scoped>
  .checkbox {
    width: min-content;
    height: min-content;
    padding: 5px;
  }
</style>
